<template>
  <section class="contact-area ver-1">
        <div class="container">
            <div class="template-title center has-over mt-5">
                <h1>Sit Back, Relax, And Enjoy the Ride</h1>
                <span>Sit Back, Relax, And Enjoy the Ride</span>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <div class="contact-item center">
                        <div class="icon">
                            <img src="/images/icon/address-1.png" alt="">
                        </div>
                        <div class="content-text">
                            <h4>Address</h4>
                            <p>All Black Limo LLC, 5800 Soundview dr A-104, Gig Harbor, WA 98335</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="contact-item center">
                        <div class="icon">
                            <img src="/images/icon/email-1.png" alt="">
                        </div>
                        <div class="content-text">
                            <h4>Email</h4>
                            <p><a href="mailto:reservations@Allblacklimoseattle.com">reservations@Allblacklimoseattle.com</a></p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="contact-item center">
                        <div class="icon">
                            <img src="/images/icon/phone-1.png" alt="">
                        </div>
                        <div class="content-text">
                            <h4>Phone</h4>
                            <p><a href="tel:877-206-0780">877-206-0780 </a></p>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-12">
                    <div class="hours center">
                        <h5>Work Hours</h5>
                        <ul>
                            <li>Monday - Friday : 08h00 - 17h30</li>
                            <li>Saturday: 08h00 - 12h00, Sunday off work</li>
                        </ul>
                    </div>
                    <div class="follow center">
                        <h5>Follow Us</h5>
                        <ul>
                            <li>
                                <a href="#" title="">
                                    <i class="fa fa-facebook" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" title="">
                                    <i class="fa fa-twitter" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" title="">
                                    <i class="fa fa-instagram" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" title="">
                                    <i class="fa fa-pinterest" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" title="">
                                    <i class="fa fa-dribbble" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" title="">
                                    <i class="fa fa-google" aria-hidden="true"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div> -->
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>
<template>
    <section class="customs-single">
        <div class="container">
            <div class="row">
                <div class="col-md-8">
                    <article class="block-customs-single mt-4">
                        <div class="entry-customs">
                            <h2>CONTRACT OF CARRIAGE</h2>
                            <p>This contract of carriage (the "Agreement") is entered into on [01/01/2023] by and between
                                All Black Limo (the "Carrier") and (the "Customer") for the transportation of passengers
                                and/or goods as outlined below.</p>
                            <ul>
                                <li><strong>SCOPE OF SERVICES:</strong><br>
                                    <p>The Carrier agrees to provide transportation services to the Customer as specified in
                                        the attached itinerary or as agreed to by both parties in writing. The Carrier will
                                        provide a chauffeur-driven luxury vehicle in excellent condition, with appropriate
                                        insurance and licenses.</p>
                                </li>

                                <li><strong>PRICE AND PAYMENT:</strong><br>
                                    <p>The price for the Carrier's services is as stated in the attached itinerary or as
                                        agreed to by both parties in writing. The price includes all transportation-related
                                        expenses, including fuel, tolls, and parking fees, but does not include gratuities.
                                        Payment is due in full before the start of the transportation service, unless
                                        otherwise agreed to by both parties in writing.</p>
                                </li>

                                <li><strong>CANCELLATION AND REFUND:</strong><br>
                                    <p>In the event that the Customer wishes to cancel a reservation with All Black Limo,
                                        notice must be provided no less than 24 hours prior to the scheduled pick-up time.
                                    </p>

                                    <p>Cancellations for one-way reservations made within 24 hours of the reservation will
                                        be billed the full fare, and no refunds will be given. There will be no refunds for
                                        no-shows, defined as Customers who do not arrive within one hour of the scheduled
                                        pickup time. </p>

                                    <p>Cancellations for hourly reservations made within 72 hours of the reservation will be
                                        billed the full fare, and no refunds will be given.</p>

                                    <p>All cancellation fees will be charged to the payment method provided at the time of
                                        booking. The Customer acknowledges and agrees to these cancellation terms by
                                        confirming the reservation.</p>
                                </li>

                                <li><strong>TERM AND TERMINATION:</strong><br>
                                    <p>This Agreement shall remain in effect until the transportation services have been
                                        completed, unless terminated earlier by either party. Either party may terminate
                                        this Agreement for any reason upon written notice to the other party.</p>
                                </li>

                                <li><strong>WARRANTIES AND REPRESENTATIONS:</strong><br>
                                    <p>The Carrier warrants and represents that all vehicles used in the provision of
                                        services under this Agreement will be in good condition and will comply with all
                                        applicable laws and regulations. The Carrier further warrants and represents that
                                        all drivers will be appropriately licensed and qualified to operate the vehicles.
                                    </p>
                                </li>

                                <li><strong>INDEMNIFICATION:</strong><br>
                                    <p>The Customer shall indemnify and hold the Carrier harmless from any and all claims,
                                        liabilities, damages, and expenses arising out of or in connection with the
                                        provision of transportation services under this Agreement, including but not limited
                                        to claims for personal injury or property damage.</p>
                                </li>

                                <li><strong>LIMITATION OF LIABILITY:</strong><br>
                                    <p>The Carrier's liability under this Agreement shall be limited to the amount paid by
                                        the Customer for the services provided. The Carrier shall not be liable for any
                                        consequential, incidental, or punitive damages arising out of or in connection with
                                        the provision of transportation services under this Agreement.</p>
                                </li>

                                <li><strong>CONFIDENTIALITY:</strong><br>
                                    <p>Both parties agree to keep all information related to this Agreement and the
                                        provision of transportation services confidential, except as required by law or with
                                        the other party's written consent.</p>
                                </li>

                                <li><strong>GOVERNING LAW AND JURISDICTION:</strong><br>
                                    <p>This Agreement shall be governed by and construed in accordance with the laws of the
                                        State of [STATE], without regard to its conflict of law principles. Any dispute
                                        arising out of or in connection with this Agreement shall be resolved through
                                        arbitration in accordance with the rules of the American Arbitration Association.
                                        The arbitration shall take place in [CITY], and the parties shall bear their own
                                        costs and attorney's fees.</p>
                                </li>

                                <li><strong>ENTIRE AGREEMENT:</strong><br>
                                    <p>This Agreement represents the entire agreement between the parties with respect to
                                        the provision of transportation services and supersedes all prior negotiations,
                                        understandings, and agreements between the parties, whether written or oral.</p>
                                </li>
                            </ul>
                            <br>
                            <p>IN WITNESS WHEREOF, the parties have executed this Agreement as of the date first above
                                written.</p>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>
